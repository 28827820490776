import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';


class Contact extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="contact" className="inner-wrapper">


          <h1>{this.props.t('Kontakt')}</h1>


            <TriangleAnimated right />
            <TriangleAnimated left />
            <TriangleAnimated right bottom />
            <TriangleAnimated left bottom />

          <div className="container">
            <div className="body">
              {this.props.t('Hier bist du richtig mit deinen Fragen und Anliegen. Wir helfen dir gerne weiter.')}
            </div>
          </div>



          <div className="container contact-bar">
            <div className="row">
              <div className="col-lg-4">
                 <span onClick={() => { window.open('https://www.google.ch/maps/place/N%C3%BCschelerstrasse+31,+8001+Z%C3%BCrich/@47.3722546,8.5335938,17z/data=!3m1!4b1!4m5!3m4!1s0x47900a0400d9cb13:0x116707038c1ea796!8m2!3d47.372251!4d8.5357825?hl=de','_blank'); }}>
                   <FontAwesomeIcon icon={AllLightIcons.faMapMarker} />
                   <strong>Office</strong><br />
                   Nüschelerstrasse 31, 8001 Zürich
                 </span>
              </div>
              <div className="col-lg-4">
                 <span onClick={() => { window.open('https://www.google.ch/maps/search/HARDTURM+STADION+Z%C3%9CRICH++%7C++Hardturmstrasse+404,+Z%C3%BCrich/@46.8358449,7.658535,9z?hl=de','_blank'); }}>
                   <FontAwesomeIcon icon={AllLightIcons.faMapMarker} />
                   <strong>{this.props.t('Festival Standort')}</strong><br />
                   Militärstrasse, 8004 Zürich
                 </span>
              </div>
              <div className="col-lg-4">
                 <span>
                  <FontAwesomeIcon icon={AllLightIcons.faEnvelope} />
                  <strong>Email</strong><br />
                   <a href="mailto: info@alba-festival.ch">info@alba-festival.ch</a>
                 </span>
              </div>

            </div>
          </div>

          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Contact);
