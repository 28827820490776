import React, {useState, useRef, useEffect} from 'react';
import ReactCookieFirst from "react-cookiefirst";

import GATracking from "../../helpers/GATracking";
import { withTranslation, Trans } from 'react-i18next';


function CookieFirst(props) {




  return (
    <>
    <ReactCookieFirst apiKey="7c8aae21-e665-491f-9fc0-cb357c5806ae">
      <GATracking />
    </ReactCookieFirst>

    </>
  );
}

export default withTranslation()(CookieFirst);
