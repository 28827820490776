import React from 'react';
import { Helmet } from 'react-helmet'


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import IntroBg from "../../assets/images/homeIntroBg.jpeg";
import homeHeaderPolygonLeft from "./assets/images/homeHeaderPolygonLeft.svg";
import homeHeaderPolygonRight from "./assets/images/homeHeaderPolygonRight.svg";
import './assets/css/index.css';


import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";

import fanTicket from "./assets/images/fanTicket.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import Ticket from "../../blocks/ticket/Ticket";

import HeaderVideo from "../../assets/videos/headerVideo2024.mp4";

import HomeCountdown from "./HomeCountdown";
import HomeCovid from "./HomeCovid";
import HomeIntroHeadline from "./HomeIntroHeadline";
import HomeScrollButton from "./HomeScrollButton";
import HomeLineup from "./HomeLineup";

import ScrollAnimation from 'react-animate-on-scroll';
import PagePreloader from "../../helpers/PagePreloader";

import { withTranslation, Trans } from 'react-i18next';
import Marquee from "react-fast-marquee";


import HomeIntroTextLeftPolygon from "./assets/images/homeIntroTextLeftPolygon.svg";
import HomeIntroTextRightPolygon from "./assets/images/homeIntroTextRightPolygon.svg";




class ThankYou extends React.Component {

  constructor(props) {
    super(props);

    ////console.log(props);

    this.state = {
      loading: true,
    };

    this.homeWelcomeRef = React.createRef();

  }


  componentDidMount() {
    this.setState({ loading: false });
    document.body.classList.add('home');
  }

  componentWillUnmount() {
    document.body.classList.remove('home');
  }




  render() {


    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="home" className="thank-page">

          <div className="intro-bar">

            <div className="video-bar">
              <video playsInline autoPlay muted loop preload="metadata" className="H_i_h" data-automation="VideoPlayer_video_video">
                <source src={HeaderVideo} type="video/webm" />
                <source src={HeaderVideo} type="video/mp4" />
                <source src={HeaderVideo} type="video/ogg" />
              </video>
            </div>

            {/*
              <div className="stroke-headline-bar-01">
                <HomeIntroHeadline text="We are one." delay="1500" />
              </div>

              <div className="stroke-headline-bar-02">
                <HomeIntroHeadline text="We are alba." delay="2500" />
              </div>
              */}

            {/*
              <HomeCovid />*/}





            <h1>Thank You</h1>
            <h2>See you in 2025</h2>

            <div className="arrow-bar">
              <HomeScrollButton scrollTo={this.homeWelcomeRef} />
            </div>


            <div className="bg-bar">
              <img src={IntroBg} />
            </div>


            <div className="triangle-bar">
              <div className="animatesTriangleLeft">
                <img src={homeHeaderPolygonLeft} />
              </div>

              <div className="animatesTriangleRight">
                <img src={homeHeaderPolygonRight} />
              </div>
            </div>


            {/*

              <div className="triangle-bar">
                <div className="animatesTriangleLeft">
                  <TriangleAnimated />
                </div>

                <div className="animatesTriangleRight">
                  <TriangleAnimated />
                </div>
              </div>
              */}

          </div>




          <div className="home-welcome-bar" ref={this.homeWelcomeRef}>


            <h6>alba Festival 2024</h6>

            <a href="https://2024.alba-festival.ch/gallery" target="_blank" className="btn btn-primary">
              <FontAwesomeIcon icon={AllLightIcons.faImage} />

              Gallery
            </a>

          </div>




        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(ThankYou);
