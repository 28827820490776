import React from 'react';
import Mailchimp from 'react-mailchimp-form'
import { withTranslation, Trans } from 'react-i18next';




class FooterNewsletter extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {





    return (
      <>
      <div className="newsletter">

        <Mailchimp
  action='https://alba-festival.us20.list-manage.com/subscribe/post?u=aa0382773801b1fe670d98115&amp;id=d53a180029'

  //Adding multiple fields:
  fields={[
   {
     name: 'EMAIL',
     placeholder: this.props.t('Deine E-Mail Adresse*'),
     type: 'email',
     required: true
   },
   {
     name: 'NAME',
     placeholder: this.props.t('Dein Name*'),
     type: 'text',
     required: true
   }
  ]}
  // Change predetermined language
  messages = {
   {
     sending: this.props.t('Bitte warten...'),
     success: this.props.t('Vielen Dank für deine Anmeldung!'),
     error: this.props.t('Du bist bereits angemeldet.'),
     empty: this.props.t('Die Angabe der E-Mail Adresse ist Pflicht.'),
     duplicate: this.props.t('Du bist bereits angemeldet.'),
     button: this.props.t('Newsletter abonnieren')
   }
  }
  // Add a personalized class
  className='<YOUR_CLASSNAME>'
  />

      </div>

      </>
    );
  }
}


export default withTranslation()(FooterNewsletter);
