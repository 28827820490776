import React, {useState} from 'react';
import TicketLogo from "../../assets/images/SEE-TICKETS_Logo_Black.jpeg";
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import { withTranslation, Trans } from 'react-i18next';

import SeeTicketsLogoWhite from "./assets/images/see-tickets-white.png";
import TicketMasterWhite from "./assets/images/logo-ticketmaster.svg";
import Marquee from "react-fast-marquee";

function Ticket(props) {


  return (
    <>
    <div className="ticket-bar">
      <Marquee speed={30} velocity={100} resetAfterTries={1} minScale={0.7} autoFill={true} pauseOnHover={true}>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank" className="btn btn-primary btn-ticket">
            <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Jetzt Ticket sichern')}
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank">
            <img src={TicketMasterWhite} className="see-tickets-logo" />
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank" className="btn btn-primary btn-ticket">
            <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Jetzt Ticket sichern')}
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank">
            <img src={TicketMasterWhite} className="see-tickets-logo" />
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank" className="btn btn-primary btn-ticket">
            <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Jetzt Ticket sichern')}
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank">
            <img src={TicketMasterWhite} className="see-tickets-logo" />
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank" className="btn btn-primary btn-ticket">
            <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Jetzt Ticket sichern')}
          </a>
        </div>

        <div className="item">
          <a href="https://www.ticketmaster.ch/artist/alba-festival-tickets/1267393" title={props.t('Jetzt Ticket sichern')} target="_blank">
            <img src={TicketMasterWhite} className="see-tickets-logo" />
          </a>
        </div>
      </Marquee>
    </div>
    </>
  );
}

export default withTranslation()(Ticket);
