import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import Marquee from "react-fast-marquee";


function FestivalHeadline(props) {


  const [headline, setHeadline] = useState(props.headline);

  useEffect(() => {
     setHeadline(props.headline);
  })


  return (

      <div className="festival-headline">
        <Marquee velocity={100} resetAfterTries={1} minScale={0.7}>

          <div className="item">
            {headline}
          </div>

          <div className="item">
            {headline}
          </div>

          <div className="item">
            {headline}
          </div>

          <div className="item">
            {headline}
          </div>

          <div className="item">
            {headline}
          </div>

          <div className="item">
            {headline}
          </div>
        </Marquee>



      </div>

  );
}

export default FestivalHeadline;
