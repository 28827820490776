import React from 'react';
import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';


class PagePreloader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }

  render() {
    return (
      <React.Fragment>

        {
          <Ripple background="linear-gradient(180deg, #e20917 0%, #e20917 100%)" color="#fff" time={1800} customLoading={this.props.page.state.loading} />
        }
        {/*<Ripple background="blur" color="#fff" time={1800} customLoading={this.props.page.state.loading} />*/}


      </React.Fragment>
    );
  }
}


export default PagePreloader;
