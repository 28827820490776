import React from 'react';
import { Helmet } from 'react-helmet'


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import IntroBg from "../../assets/images/homeIntroBg.jpeg";
import homeHeaderPolygonLeft from "./assets/images/homeHeaderPolygonLeft.svg";
import homeHeaderPolygonRight from "./assets/images/homeHeaderPolygonRight.svg";
import './assets/css/index.css';


import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import Ticket from "../../blocks/ticket/Ticket";

import HeaderVideo from "../../assets/videos/headerVideo.mp4";

import HomeCountdown from "./HomeCountdown";
import HomeCovid from "./HomeCovid";
import HomeIntroHeadline from "./HomeIntroHeadline";
import HomeScrollButton from "./HomeScrollButton";
import HomeLineup from "./HomeLineup";

import ScrollAnimation from 'react-animate-on-scroll';
import PagePreloader from "../../helpers/PagePreloader";

import { withTranslation, Trans } from 'react-i18next';
import Marquee from "react-fast-marquee";


import HomeIntroTextLeftPolygon from "./assets/images/homeIntroTextLeftPolygon.svg";
import HomeIntroTextRightPolygon from "./assets/images/homeIntroTextRightPolygon.svg";




class Home extends React.Component {

  constructor(props) {
    super(props);

    ////console.log(props);

    this.state = {
      loading: true,
    };

    this.homeWelcomeRef = React.createRef();

  }


  componentDidMount() {
    this.setState({ loading: false });
    document.body.classList.add('home');
  }

  componentWillUnmount() {
    document.body.classList.remove('home');
  }




  render() {


    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="home">

          <div className="intro-bar">

            <div className="video-bar">
              <video playsInline autoPlay muted loop preload="metadata" className="H_i_h" data-automation="VideoPlayer_video_video">
                <source src={HeaderVideo} type="video/webm" />
                <source src={HeaderVideo} type="video/mp4" />
                <source src={HeaderVideo} type="video/ogg" />
              </video>
            </div>





            {/*
              <HomeCovid />*/}




            <div className="bg-bar">
              <img src={IntroBg} />
            </div>


            <div className="triangle-bar">
              <div className="animatesTriangleLeft">
                <img src={homeHeaderPolygonLeft} />
              </div>

              <div className="animatesTriangleRight">
                <img src={homeHeaderPolygonRight} />
              </div>
            </div>


            {/*

              <div className="triangle-bar">
                <div className="animatesTriangleLeft">
                  <TriangleAnimated />
                </div>

                <div className="animatesTriangleRight">
                  <TriangleAnimated />
                </div>
              </div>
              */}

          </div>



          <div className="home-welcome-bar" ref={this.homeWelcomeRef}>


              <div className="container-fluid">

                <div className="body">


                    Nach monatelangem Hin und Her mit verschiedenen Departementen der Stadt Zürich haben wir erst diesen Montag das finale Datum für ein Anfang September angedachtes alba Festival 2023 erhalten.<br /><br />

                    Leider viel zu spät.<br />
                    In sieben Wochen lässt sich kein Festival seriös umsetzen und vermarkten.<br /><br />

                    Deshalb müssen wir schweren Herzens das diesjährige alba Festival absagen. <br /><br />

                    Wir vertrauen darauf, dass die Zürcher Behörden – wie sie es versprochen haben – mit uns noch dieses Jahr einen Standort in der Stadt Zürich und ein Datum im Sommer 2024 finden und bewilligen.<br /><br />

                    Alle gekauften Fan Tickets werden zurückerstattet. <br /><br />

                    Stay tuned and excited - we will be back in 2024!<br /><br />

                    Dein alba Festival – Team<br /><br />

                    ----------<br /><br />

                    Dear friends of the alba Festival<br />

                    After months of back and forth with various departments of the city of Zurich, it was only this Monday that we received the final proposal date for the alba Festival 2023. <br /><br />

                    Unfortunately, September is much too late. <br />
                    No festival can be realised and marketed in seven weeks. <br /><br />

                    Therefore, it is with a heavy heart that we have to cancel this years alba Festival and trust that the Zurich authorities - as they promised - will find and approve a location and a date in the city of Zurich for summer 2024 with us this year. 

                  <br /><br />  All purchased tickets will be refunded. 

                    <br /><br />Stay tuned and excited - we will be back in 2024! 

                    Your alba Festival - Team<br /><br />

                    —————<br /><br />

                    Të dashur miq të alba Festival<br /><br />

                    Pas muajsh shëtitje në departamente të ndryshme të qytetit të Cyrihut, këtë të hënë ne morëm datën e propozimit përfundimtar për alba Festival 2023. <br /><br />

                    Fatkeqësisht, shtatori është shumë vonë. Asnjë festival nuk mund të realizohet dhe tregtohet në shtatë javë.<br /><br />

                    Prandaj, me zemër të renduar duhet të anulojmë alba Festivalin të këtij viti (2023) dhe po ashtu besojmë se gjatë këtë viti, autoritetet e Cyrihut siç premtuan do të gjejnë dhe miratojnë një vendndodhje dhe një datë në qytetin e Cyrihut për verën 2024.

                    <br /><br />Të gjitha biletat e blera do të rimbursohen.

                    Qëndroni me ne - do të kthehemi në 2024!<br /><br />

                    I juaji Alba Festival<br /><br />


                </div>


              </div>


              <div className="animatesTriangleLeft">
                <img src={HomeIntroTextLeftPolygon} />
              </div>

              <div className="animatesTriangleRight">
                <img src={HomeIntroTextRightPolygon} />
              </div>
          </div>








        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Home);
