import React, {useState} from 'react';
import { useSpring, animated, config } from '@react-spring/web'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


function HomeScrollButton(props) {

  const styles = useSpring({
      loop: true,
      from: { y: "-0px" },
      to: { y: "5px" },
      config: config.default
    })

    function onClick(e) {
      //console.log("Hi there, user!");

      props.scrollTo.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    }

  return (

    <animated.button
      style={{
      width: 80,
      height: 80,
      backgroundColor: '#46e891',
      borderRadius: 16,
      ...styles,
    }}

    onClick={onClick}
        children="Click me"
      >
      <FontAwesomeIcon icon={AllLightIcons.faChevronDoubleDown} />
    </animated.button>

  );
}

export default HomeScrollButton;
