import { useState, useEffect, useRef } from 'react'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';

function InfoAccordions(props) {


  const [loading, setLoading] = useState(true);
  const [infos, setInfos] = useState(null);
  const [icons, setIcons] = useState(null);
  const [session, setSession] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [currentLang, setCurrentLang] = useState(props.i18n.language);


  function loadData(props) {
    var lang = '';

    if(props.i18n.language != 'de') {
      lang = props.i18n.language + '/';
    }


    let filterString = '';
    if(categoryID) {
      //filterString = '&filter[field_info_category.meta.drupal_internal__target_id][value]='+categoryID;
    }

    axios({
      method: 'GET',
      url: config.backendBase+lang+'jsonapi/node/info?include=field_svg_icon',
      headers: {
        'Content-Type': 'json',
        'X-CSRF-Token': session,
      }
    })
      .then(res => {
        res.data.data.sort((a,b) => (a.attributes.title > b.attributes.title) ? 1 : ((b.attributes.title > a.attributes.title) ? -1 : 0))
        ////console.log(res.data.data);
        setInfos(res.data.data);
        setIcons(res.data.included);
        setLoading(false);
      })
      .catch(err => {
        ////console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
   setCurrentLang(props.i18n.language);


   if(!session) {
     const result = axios(
       config.backendBase + 'session/token',
     );

     setSession(result.data);


     loadData(props);
   } else {
     loadData(props);
   }

 }, [props.i18n.language]);


 useEffect(() => {
     loadData(props);
  }, [categoryID]);


 function filterCategory(id) {
   setCategoryID(id);
   setLoading(true);
 }




  return (
    <>


    {/*
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class={ (categoryID === null) ? 'nav-link active' :'nav-link'} onClick={() => {filterCategory(null)}} id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">Alle</button>
          <button class={(categoryID === 1) ? 'nav-link active' :'nav-link'} onClick={() => {filterCategory(1)}} id="nav-travel-tab" data-bs-toggle="tab" data-bs-target="#nav-travel" type="button" role="tab" aria-controls="navtravelhome" aria-selected="false">An- und Abreise</button>
          <button class={(categoryID === 2) ? 'nav-link active' :'nav-link'} onClick={() => {filterCategory(2)}} id="nav-entry-tab" data-bs-toggle="tab" data-bs-target="#nav-entry" type="button" role="tab" aria-controls="nav-entry" aria-selected="false">Einlass</button>
          <button class={(categoryID === 3) ? 'nav-link active' :'nav-link'} onClick={() => {filterCategory(3)}} id="nav-security-tab" data-bs-toggle="tab" data-bs-target="#nav-security" type="button" role="tab" aria-controls="nav-security" aria-selected="false">Sanitär & Sicherheit</button>
          <button class={(categoryID === 4) ? 'nav-link active' :'nav-link'} onClick={() => {filterCategory(4)}} id="nav-services-tab" data-bs-toggle="tab" data-bs-target="#nav-services" type="button" role="tab" aria-controls="nav-services" aria-selected="false">Services</button>
        </div>
      </nav>*/}

    {loading &&
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>
      <div className="accordion" id="accordion-info">






        {infos &&
          <>

          {infos.map(function(info, idx){

             return (

               <div className="accordion-item" key={idx}>
                 <h2 className="accordion-header" id={'heading-' + idx}>
                   <button className={`accordion-button ${idx > 0 ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={'#collapse-' + idx} aria-expanded="true" aria-controls={'collapse-' + idx}>
                     {info.relationships.field_svg_icon.data &&
                       <img src={config.backendBase + icons.find(x => x.id === info.relationships.field_svg_icon.data.id).attributes.uri.url} className="img-fluid" />
                     }

                      {info.attributes.title}
                   </button>
                 </h2>
                 <div id={'collapse-' + idx} className={`accordion-collapse collapse ${idx == 0 ? "show" : ""}`} aria-labelledby={'heading-' + idx} data-bs-parent="#accordion-info">
                   <div className="accordion-body">
                     <div dangerouslySetInnerHTML={{ __html: info.attributes.body.value }} />
                   </div>
                 </div>
               </div>

             )
           })}
          </>
        }


      </div>
      </>
    }
    </>
  );
}

export default withTranslation()(InfoAccordions);
