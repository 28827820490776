import React, {useState} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


function HomeCovid(props) {



  return (

    <div className="covid-bar">
      {/* show btn Modal */}
      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#covidModal">
        <FontAwesomeIcon icon={AllLightIcons.faExclamationCircle} /> Covid Infos
      </button>
    </div>

  );
}

export default withTranslation()(HomeCovid);
