import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { history } from './history';
import React, { lazy, Suspense, useEffect } from 'react'
import { withTranslation, Trans } from 'react-i18next';

import { useCookieFirst } from "react-cookiefirst";


import ReactGA from 'react-ga';

function GATracking(props) {


  const ctx = useCookieFirst();


  if(ctx.consent  && ctx.consent.advertising) {
    ReactGA.initialize('UA-126865055-7');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }


    var onlisten = history.listen((location, action) => {
      if(ctx.consent  && ctx.consent.advertising) {
        ReactGA.initialize('UA-126865055-7');
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    });


  return (
    <></>
  );
}

export default withTranslation()(GATracking);
