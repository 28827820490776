import { useState, useEffect, useRef } from 'react'
import { Modal } from 'bootstrap'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import { withTranslation, Trans } from 'react-i18next';
import config from '../../config';

function TicketsGrid(props) {


  const [session, setSession] = useState(0);
  const [currentLang, setCurrentLang] = useState(props.i18n.language);
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState(null);
  const [images, setImages] = useState(null);



  useEffect(() => {
   setCurrentLang(props.i18n.language);


   if(!session) {
     const result =  axios(
       config.backendBase + 'session/token',
     );

     setSession(result.data);
     var lang = '';

     if(props.i18n.language != 'de') {
       lang = props.i18n.language + '/';
     }

     axios({
       method: 'GET',
       url: config.backendBase+lang+'jsonapi/node/ticket?include=field_image&sort=field_arrangement',
       headers: {
         'Content-Type': 'json',
         'X-CSRF-Token': session,
       }
     })
       .then(res => {
         ////console.log(res);
         setContents(res.data.data);
         setImages(res.data.included);
         setLoading(false);
       })
       .catch(err => {
         ////console.log(err);
         setLoading(false);
       });
   }

 }, [props.i18n.language]);





  return (
    <>

    {loading &&
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    }

    {!loading &&
      <>
      <div className="container ticket-grid">

          {contents.map(function(content, idx){

            //if(artist.attributes.field_date)

            var odd = idx % 2 == 0;

             return (

               <div className={`row ${odd ? "odd" : "even"}`} key={idx}>

                 <div className="col-md-6">
                   <img src={config.backendBase + images.find(x => x.id === content.relationships.field_image.data.id).attributes.uri.url} className="img-fluid" />
                 </div>
                 <div className="col-md-6">
                   <h2>{content.attributes.title}</h2>
                   <div className="body">
                     <div dangerouslySetInnerHTML={{ __html: content.attributes.body.value }} />
                     <div className="price">
                       {content.attributes.field_price} CHF
                     </div>
                   </div>

                   <div className="call-to-action">

                     {!content.attributes.field_sold_out &&
                       <a href={content.attributes.field_link} target="_blank" title={props.t('Ticket bestellen')} className="btn btn-primary">
                         <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('Ticket bestellen')}
                      </a>
                     }

                     {content.attributes.field_sold_out &&
                       <a href={'#'} title={props.t('ausverkauft')} className="btn btn-primary">
                         <FontAwesomeIcon icon={AllLightIcons.faTicket} /> {props.t('ausverkauft')}
                      </a>
                     }
                   </div>
                 </div>
               </div>



             )
           })}

      </div>
      </>
    }
    </>
  );
}

export default withTranslation()(TicketsGrid);
