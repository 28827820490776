import { useState, useEffect, useRef } from 'react'
import Ticker from 'react-ticker'
import { Modal } from 'bootstrap'
import HomeArtist from '../home/HomeArtist'
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'
import Marquee from "react-fast-marquee";

function ArtistHeadline(props) {


  const [speed, setSpeed] = useState(5);
  const [loading, setLoading] = useState(true);




  function onMouseEnterHandler(artist) {
    setSpeed(1);
  }

  function onMouseLeaveHandler(artist) {
    setSpeed(5);
  }





  return (
    <>



      <>


      <Marquee velocity={100} resetAfterTries={1} minScale={0.7}>

        <div className="item">
          {props.artist.attributes.title}
        </div>

        <div className="item">
          {props.artist.attributes.title}
        </div>

        <div className="item">
          {props.artist.attributes.title}
        </div>

        <div className="item">
          {props.artist.attributes.title}
        </div>

        <div className="item">
          {props.artist.attributes.title}
        </div>

        <div className="item">
          {props.artist.attributes.title}
        </div>
      </Marquee>
    


      </>
    </>
  );
}

export default ArtistHeadline;
