import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import './assets/css/index.css';
import Ticker from 'react-ticker'
import FestivalHeadline from "./FestivalHeadline";
import PagePreloader from "../../helpers/PagePreloader";
import Ticket from "../../blocks/ticket/Ticket";
import FestivalGrid from "./FestivalGrid";
import { withTranslation, Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import PlanImage from "./assets/images/plan.jpg";



class Festival extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="festival" className="inner-wrapper">

          <TriangleAnimated right />
          <TriangleAnimated left />
          <TriangleAnimated right bottom />
          <TriangleAnimated left bottom />


          <h1>{this.props.t('Festival')}</h1>

          <div className="container">
            <div className="body">
              {this.props.t('Unser Wunsch ist es, Menschen aus ganz Europa zu vereinigen und gemeinsam drei unvergessliche Tage zu erleben. Mit dem alba Openair-Festival möchten wir etwas albanische Kultur nach Zürich bringen. ')}

              {this.props.t('Das alba Festival heisst alle Musik- und Kulturliebhaber herzlich willkommen.')}


            </div>
          </div>

          <div className="container festival-plan-bar">
            <button style={{background: 'none', border: 'none'}} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planModal">
              <img src={PlanImage} />
            </button><br />



          {/*<a href="/360/index.html" target="_blank" className="btn btn-primary btn-tour">
            <FontAwesomeIcon icon={AllLightIcons.faStreetView} /> 360° Tour
          </a>*/}

          <br /><br />


            {/* Modal */}
            <div className="modal fade" id="planModal" tabIndex="-1" aria-labelledby="planModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="planModalLabel">Plan</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body text-start">

                      <img src={PlanImage} width="100%" />

                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className="container-fluid container-list">
            <FestivalGrid />
          </div>

          <Ticket />


        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Festival);
