import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import LineupGrid from "./LineupGrid";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';


class Lineup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>


        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>


        <section id="lineup" className="inner-wrapper">



          <TriangleAnimated right />
          <TriangleAnimated left />
          <TriangleAnimated right bottom />
          <TriangleAnimated left bottom />

          <h1>{this.props.t('Line-up Pop & Hiphop Acts')}</h1>

          <div className="body container">
              {this.props.t('Eines der schrägsten Musikphänomene zurzeit ist mit Sicherheit der kultur-, sprach- und grenzübergreifende Grosserfolg zahlloser Pop- und Hiphop-Acts mit albanischen oder kosovarischen Wurzeln. An diesen musikalischen Albanien/Kosovo-Exporten kommt niemand vorbei. Sie stürmen die internationalen Charts und zählen gemäss Spotify-Statistiken auch zu den meist gestreamten der Schweiz. Es wimmelt derzeit nur so von angesagten Künstlerinnen und Künstlern mit albanischen Wurzeln und Pop-Einfluss, die es am alba Festival in Zürich zu entdecken gilt.')}
          </div>


          <LineupGrid />

          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Lineup);
