import React from 'react'
import ReactDOM from 'react-dom'

import { withTranslation, Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import './assets/css/index.css'

import PlayerBanner from './assets/images/alba-playlist.jpg'

class Player extends React.Component {

  constructor(props) {
    super(props);
  }
  render() {

    return (
      <>


      <button type="button" class="btn btn-primary"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasPlayer" id="playerModalBtn" aria-controls="offcanvasPlayer">
        <FontAwesomeIcon icon={AllLightIcons.faBoombox} />
      </button>






      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasPlayer" aria-labelledby="offcanvasPlayerLabel" >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasPlayerLabel">Official Playlist</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">

          <a href="https://albafestival.lnk.to/officialplaylist" target="_blank">
            <img src={PlayerBanner} class="img-fluid" />
          </a>

          <hr />

          <iframe src="https://open.spotify.com/embed/playlist/17fBgveWg4OvaeCVlYky0z?utm_source=generator" width="100%" height="78%" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>


        </div>
      </div>


      </>

    )
  }
}

export default withTranslation()(Player);
