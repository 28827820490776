// Import React
import * as React from 'react';

import CustomerMarker from '../../assets/images/map-marker-duotone.svg';

// Import necessary components for React Google Maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'

// Import custom styles to customize the style of Google Map
const styles = require('./GoogleMapStyles.json')

// Import custom icon for map marker
// You can use this if you need to support IE11 and lower.
// const mapMarker = require('./GoogleMapMarker.svg')

// Google Map component
const FooterMap = withScriptjs(
  withGoogleMap(props => (

    <GoogleMap
      defaultZoom={15}
      defaultCenter={{
        lat: 47.377670, // latitude for the center of the map
        lng: 8.531290 // longitude for the center of the map
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: false, // allow scroll wheel
        styles: styles // change default map styles
      }}
    >
      <Marker
        onClick={() => window.open('https://www.google.com/maps/dir//Kasernenareal,+Milit%C3%A4rstrasse,+8004+Z%C3%BCrich/@47.3754455,8.5339361,14.78z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47900a054b8cef25:0xb9353b498630c24!2m2!1d8.5314491!2d47.3763734!3e3?entry=ttu', '_blank')}
        position={{
          lat: 47.377670, // latitude for the center of the map
          lng: 8.531290 // longitude for the center of the map
        }}
      />
    </GoogleMap>
  ))
)


// Export Google Map component
export default FooterMap;
