import React from 'react';
import { Helmet } from 'react-helmet'

import { Link } from "react-router-dom";


import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation, Trans } from 'react-i18next';


import Festival2019 from "./assets/images/alba-festival-history-2019.jpg";
import Festival2021 from "./assets/images/alba-festival-history-2021.jpg";
import Festival2022 from "./assets/images/alba-festival-history-2022.jpg";



class History extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="history" className="inner-wrapper">
          <h1>{this.props.t('History')}</h1>


            <TriangleAnimated right />
            <TriangleAnimated left />
            <TriangleAnimated right bottom />
            <TriangleAnimated left bottom />


          <div className="container">
            <div className="body">
              {this.props.t('Entdecke die besten Impressionen des vergangenen Festivals.')}
            </div>
          </div>




          <div className="container">
            <div className="row">
              <div className="col-4">


                  {/* show btn Modal */}
                  <Link style={{textDecoration: 'none'}} to="#" type="button" className="">
                    <img src={Festival2019} className="img-fluid" />
                    <h3>2019</h3>
                  </Link>



              </div>
              <div className="col-4">

                <a style={{textDecoration: 'none'}} href="https://2021.alba-festival.ch" target="_blank" type="button" className="">
                  <img src={Festival2021} className="img-fluid" />
                  <h3>2021</h3>
                </a>


              </div>

              <div className="col-4">

                <a style={{textDecoration: 'none'}} href="https://2022.alba-festival.ch" target="_blank" type="button" className="">
                  <img src={Festival2022} className="img-fluid" />
                  <h3>2022</h3>
                </a>


              </div>
            </div>
          </div>


          <Ticket />

        </section>


        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(History);
