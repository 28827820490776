import React, { useState, useEffect } from 'react';
import axios from "axios";
import Fancybox from "./fancybox.js";
import { Card, List, Pagination, Image } from 'antd';

import config from '../../../config';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'


const GalleryGrid = (props) => {

  const [session, setSession] = useState(0);
  const [gallery, setGallery] = useState(0);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState(null);
  let galleryGrid;

  const delegate = props.delegate || "[data-fancybox]";


  useEffect(async () => {

    const opts = props.options || {};




    if(!session) {
      const result = await axios(
        config.backendBase + 'session/token',
      );

      setSession(result.data);

      return axios({
        method: 'GET',
        url: config.backendBase + 'jsonapi/node/gallery/'+props.uuid+'?include=field_gallery_images',
        headers: {
          'Content-Type': 'json',
          'X-CSRF-Token': session,
        }
      })
        .then(res => {
          //console.log(res);
          let gallery = res.data.data;

          setLoading(false);
          setImages(res.data.included);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  });

  return (
    <>

      {loading &&
        <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>

      }

      {!loading && images &&
        <>
        <Fancybox options={{ infinite: false }}>

          <List
            pagination={{
              pageSize: 24,
              showSizeChanger: false,
              onChange: function(page, pageSize) {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
                //console.log(page);
              }
            }}
            grid={{
              gutter: 24,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={images}
            renderItem={(item, idx) => (
              <List.Item>
                <div key={item.id}>
                  <button
                    data-fancybox={'gallery'+props.uuid}
                    data-src={config.backendBase + item.attributes.uri.url}
                    >

                    <img src={config.backendBase + item.attributes.uri.url} className="img-fluid" />

                  </button>
                </div>

              </List.Item>
            )}
          />

        </Fancybox>
        </>
      }
    </>
  );
};

export default GalleryGrid;
